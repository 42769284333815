@charset "UTF-8";
/* CSS Document */

@font-face{font-family: 'MyriadPro'; src:url('../../fonts/2022/MyriadPro-Regular.woff'); src:url('../../fonts/2022/MyriadPro-Regular.woff') format('woff'); font-weight:normal; font-style:normal;}
@font-face{font-family: 'MyriadPro'; src:url('../../fonts/2022/MyriadPro-Bold.woff'); src:url('../../fonts/2022/MyriadPro-Bold.woff') format('woff'); font-weight:bold; font-style:normal;}
@font-face{font-family: 'NotoSans'; src:url('../../fonts/2022/NotoSansHK-Regular.otf'); src:url('../../fonts/2022/NotoSansHK-Regular.otf') format('opentype'); font-weight:normal; font-style:normal;}
@font-face{font-family: 'NotoSans'; src:url('../../fonts/2022/NotoSansHK-Bold.otf'); src:url('../../fonts/2022/NotoSansHK-Bold.otf') format('opentype'); font-weight:bold; font-style:normal;}


html, body{width:100%; min-width:375px; margin:0; padding:0; -webkit-text-size-adjust:100%; -webkit-font-smoothing:antialiased !important; -moz-osx-font-smoothing:grayscale; font-family:MyriadPro,NotoSans,Helvetica,'新微軟正黑體',Arial,'微軟正黑體','Microsoft JhengHei',sans-serif; font-weight:normal; font-size:calc(21px + (48 - 21) * ((100vw - 375px) / (1600 - 375))); background-color:#FFFFFF; color:#333333; font-feature-settings:'kern'; text-rendering:optimizeLegibility; -webkit-overflow-scrolling:touch;}
html.Fixed, body.Fixed{overflow:hidden;}
*{box-sizing:border-box;}
h1{font-size:calc(22px + 0.22rem); line-height:130%; font-weight:bold; color:#E8366F; text-align:center;}
h1.Margin{margin:calc(60px + 1rem) 0;}
h2{font-size:calc(15px + 0.2rem); line-height:130%; font-weight:normal; color:#666666; text-align:center;}
h3{font-size:calc(15px + 0.2rem); margin:0px; line-height:130%; font-weight:bold; text-align:center;}
h3 span{display:inline-block; margin:0 calc(8px + 0.1rem);}
h4{font-size:calc(13px + 0.16rem); margin:0px; padding:calc(13px + 0.16rem) 0; line-height:130%; font-weight:bold; text-align:center;}
h5{font-size:calc(15px + 0.2rem); margin:0px; line-height:130%; font-weight:bold;}
h6{font-size:calc(11px + 0.1rem); margin:0px; line-height:130%; font-weight:normal;}
p{width:100%; font-size:calc(0.13rem + 10px); text-align:justify; margin:calc(15px + 1vw) 0px;}
a, a:visited{color:#00A5CF; text-decoration:underline;}
a:hover{color:#F27225;}
figure{margin:calc(8px + 0.1rem) 0 calc(16px + 0.7rem) 0;}
figure img{width:100%; max-width:1200px; display:block; margin:auto;}
figcaption{font-size:calc(0.1rem + 8px); width:100%; display:block; text-align:center; margin:calc(3px + 0.5vw) 0 calc(10px + 1vw) 0; color:#666666;}
.SectionEventName{width:100%; display:block; margin-top:calc(3vw + 100px);}
.SectionEventName img{width:auto; max-width:100%; height:auto; max-height:50px; display:block; margin:auto;}
.SectionHeader{font-size:calc(22px + 0.3rem); line-height:130%; font-weight:bold; color:#EC6914; text-align:center;}
.ButtonWrapper{flex:0 0 100% !important; width:100% !important; margin:calc(30px + 0.2rem) 0; display:flex; flex-wrap:nowrap; justify-content:center; border:0 !important;}
.ButtonWrapper a{flex:0 0 auto; width:auto; display:block; padding:calc(10px + 0.1rem) calc(30px + 0.2rem); background-color:#0054A6; color:#FFFFFF; text-decoration:none; border-radius:6px; -webkit-box-shadow:0px 20px 10px 0px rgba(0,0,0,0.2); box-shadow:0px 20px 10px 0px rgba(0,0,0,0.2); line-height:0; font-size:0; transition:0.2s ease-in;}
.ButtonWrapper a h4{margin:0; padding:0;}
.ButtonWrapper a:hover{background-color:#EE6914; transition:0.2s ease-out;}

/* Grid */
#MainWrapper{width:100%; min-height:100%; position:relative;}
.MainWrapperBg::before{content:""; width:100%; height:100%; position:absolute; top:0; left:0; display:block; background-image:url("../../images/2022/bgtop.png"); background-size:100%; background-position:top center; background-repeat:no-repeat;}
section{display:block; width:100%; max-width:1200px; margin:calc(2vw + 20px) auto; overflow:hidden; position:relative;}
section.Full{max-width:none;}
section.noTopMargin{margin-top:0;}
section.noBottomMargin{margin-bottom:calc(1vw + 10px);}
section.LMargin{margin-top:calc(100px + 2vw);}
section:first-of-type{margin-top:0;}
section.Border{border-top:1px solid #E2E3E4; max-width:none;}
section.Border .Container{width:100%; max-width:1200px; margin:calc(2vw + 20px) auto; }
.Container{width:100%; padding:0px calc(10px + 2vw); margin:auto;}
article p + .Logo{margin-top:calc(20px + 1rem);}
article .Logo img{width:auto; max-width:100%; height:auto; max-height:calc(40px + 1.5vw);}
article ul{width:100%; list-style:none; margin:calc(20px + 0.2rem) 0 calc(50px + 0.2rem) 0; padding:0; text-align:center; display:flex; flex-wrap:wrap; justify-content:center;}
article li{flex:0 0 auto; display:block; text-align:center; margin:10px; padding:0; font-size:calc(0.15rem + 10px);}
article h1 + ul{margin-top:0;}

/* Nav */
nav{width:100%; height:60px; overflow:hidden; background-color:rgba(240,233,222,1); position:fixed; top:0; left:0; z-index:999999; -webkit-backdrop-filter:blur(6px); backdrop-filter:blur(6px); display:none; flex-wrap:nowrap; justify-content:center; align-items:center; align-content:center; -webkit-box-shadow:0px 10px 10px 0px rgba(0,0,0,0.1); box-shadow:0px 10px 10px 0px rgba(0,0,0,0.1);}
#MenuWrapper{width:50%; max-width:1200px; min-width:260px; margin:0 auto; display:flex; flex-wrap:wrap; justify-content:center;}
#MenuWrapper a{flex:0 0 100%; padding:16px 20px; margin:10px 0; font-size:calc(14px + 0.15rem); color:#333333; text-decoration:none; text-align:center; border:1px solid rgba(0,0,0,0.2); border-radius:1000px; transition:0.2s ease-in;}
#MenuWrapper a:hover{background-color:#E8366F; color:#FFFFFF; transition:0.2s ease-out;}
#MenuWrapper a.Selected{background-color:#E8366F; color:#FFFFFF; border:0;}
#MenuWrapper a.Selected:hover{background-color:#E8366F; color:#FFFFFF;}
nav.Expand{height:100vh; display:flex;}
#MenuIcon{width:60px; height:60px; position:fixed; top:10px; left:10px; z-index:9999999; box-sizing:content-box; cursor:pointer; display:block; transition:background-color ease-in 0.2s; -ms-user-select:none; user-select:none; border:none; background-color:rgba(255,255,255,0.9); -webkit-backdrop-filter:blur(6px); backdrop-filter:blur(6px); -webkit-box-shadow:0px 10px 10px 0px rgba(0,0,0,0.1); box-shadow:0px 10px 10px 0px rgba(0,0,0,0.1); border-radius:1000px;}
#MenuIcon > div, #MenuIcon > div > div{position:absolute; display:block; pointer-events:none;} 
#MenuIcon > div{width:32px; height:24px; top:50%; left:50%; transform:translate(-50%,-50%);} 
#MenuIcon .Default{height:4px; top:0px; left:0px; width:32px; opacity:1; background-color:#333333;} 
#MenuIcon > div > div:nth-of-type(1){transform:translate(0px, 0px); transition:width ease-in 0.1s, opacity ease-in 0.1s; transition-delay:0.05s;}
#MenuIcon > div > div:nth-of-type(2){transform:translate(0px, 10px); transition:width ease-in 0.2s, opacity ease-in 0.2s; transition-delay:0.15s;}
#MenuIcon > div > div:nth-of-type(3){transform:translate(0px, 20px); transition:width ease-in 0.3s, opacity ease-in 0.3s; transition-delay:0.25s;}
#MenuIcon > div > div.Extend{height:4px; top:0px; left:0px; width:0px; transform-origin:center center; opacity:0; background-color:#333333;}
#MenuIcon > div > div:nth-of-type(4){transform:translate(-2px, -3px) rotate(0deg); transition:width ease-in 0.1s, opacity ease-in 0.1s, transform ease-in 0.1s;}
#MenuIcon > div > div:nth-of-type(5){transform:translate(-2px, 24px) rotate(0deg); transition:width ease-in 0.3s, opacity ease-in 0.3s, transform ease-in 0.3s;}
#MenuIcon.Expand{-webkit-box-shadow:0px 10px 10px 0px rgba(0,0,0,0); box-shadow:0px 10px 10px 0px rgba(0,0,0,0); background-color:transparent; transition:ease-out 0.2s;}
#MenuIcon.Expand .Default{width:0px; opacity:0;} 
#MenuIcon.Expand > div > div:nth-of-type(1){transition:width ease-out 0.1s, opacity ease-out 0.1s;}
#MenuIcon.Expand > div > div:nth-of-type(2){transition:width ease-out 0.2s, opacity ease-out 0.2s;}
#MenuIcon.Expand > div > div:nth-of-type(3){transition:width ease-out 0.3s, opacity ease-out 0.3s;}
#MenuIcon.Expand .Extend{width:32px; opacity:1;} 
#MenuIcon.Expand > div > div:nth-of-type(4){transform:translate(0px, 11px) rotate(45deg); transition:width ease-out 0.1s, opacity ease-out 0.1s, transform ease-out 0.1s; transition-delay:0.05s;}
#MenuIcon.Expand > div > div:nth-of-type(5){transform:translate(0px, 11px) rotate(-45deg); transition:width ease-out 0.25s, opacity ease-out 0.25s, transform ease-out 0.25s; transition-delay:0.1s;}


/* Footer */
footer{border-top:1px solid #CCCCCC; background-color:#FFFFFF; padding:calc(50px + 1rem) 0;}
.FooterWrapper{width:100%; display:flex; flex-wrap:wrap; justify-content:center;}
.FooterWrapper h6{flex:0 0 100%; width:100%; font-size:calc(9px + 0.1rem); font-weight:normal; text-align:center; margin-bottom:7px;}
.FooterWrapper div{flex:0 0 100%; width:100%; display:flex; flex-wrap:wrap; justify-content:center; margin:calc(15px + 0.1rem); margin-bottom:0;}
.FooterWrapper div a{flex:0 0 auto;}
.FooterWrapper div a img{width:auto; height:auto; max-height:40px; display:block;}
.FooterWrapper div.Organiser{margin-bottom:20px;}
.FooterWrapper div.Organiser a img{max-height:45px;}


/* Home */
#HomeKVSection{background-color:#F37021; padding-bottom:calc(30px + 1vw);}
#HomeKVWrapper{width:100%; max-width:1200px; height:auto; display:flex; flex-wrap:wrap; justify-content:center; padding-top:100px; margin:auto;}
#HomeKVWrapper > div{flex:0 0 100%; width:100%;}
#HomeKVWrapper > div img{width:100%; max-width:500px; height:auto; display:block; margin:auto;}
#HomeKVLeadin{width:100%; max-width:500px; text-align:justify; color:#FFFFFF; font-size:calc(9px + 0.18rem); line-height:normal; margin:calc(20px + 2vw) auto calc(10px + 1vw);}

#HomePanel{position:relative; width:100%; height:auto; padding:calc(40px + 0.5rem) 0; background:rgb(245,233,211); background:radial-gradient(circle, rgba(245,233,211,1) 0%, rgba(255,255,255,1) 100%); -webkit-box-shadow:0px 0px 15px 0px rgba(0,0,0,0.1); box-shadow:0px 0px 15px 0px rgba(0,0,0,0.1);}
#HomePanel::before, #HomePanel::after{content:""; position:absolute; left:0; z-index:1; background-size:100%; background-repeat:no-repeat; width:100%; height:100%;}
#HomePanel::before{background-image:url("../../images/2022/bgtop.png"); background-position:top center; top:0;}
#HomePanel::after{background-image:url("../../images/2022/bgbottom.png"); background-position:bottom center; bottom:0;}
#HomePanel .Container{width:100%; max-width:1200px; margin:0 auto; z-index:2; position:relative;}
.HomePanelWrapper{width:100%; display:flex; flex-wrap:wrap; justify-content:space-between; align-items:stretch;}
.HomePanelWrapper > div{flex:0 0 100%; width:100%; display:flex; flex-wrap:wrap; justify-content:space-around; align-content:flex-start; align-items:stretch; padding:calc(5px + 0.2rem) calc(10px + 0.4rem);}
.HomePanelWrapper > div > div{flex:0 0 100%; width:100%; max-width:400px; display:flex; flex-wrap:nowrap; justify-content:center; align-items:center; margin:calc(8px + 0.05rem) auto;}
.HomePanelWrapper div.Thumbnail{flex:0 0 calc(60px + 1rem); width:calc(60px + 1rem); max-width:100px; margin-right:20px;}
.HomePanelWrapper div.Thumbnail > div{width:100%; max-width:160px; overflow:hidden; border-radius:1000px; -webkit-box-shadow:0px 10px 10px 0px rgba(0,0,0,0.2); box-shadow:0px 10px 10px 0px rgba(0,0,0,0.2); line-height:0; font-size:0; margin:auto;}
.HomePanelWrapper div.Thumbnail img{width:100%; display:block;}
.HomePanelWrapper div.Bio{flex:1 1 auto;}
.HomePanelWrapper h1{color:#EF406B;}
.HomePanelWrapper div.Bio h5{font-size:calc(12px + 0.12rem);}
.HomePanelWrapper div.Bio p{font-size:calc(10px + 0.05rem); margin:calc(10px + 0.1rem) 0px; line-height:120%;}
.HomePanelWrapper div.Bio p:first-of-type{margin-bottom:5px; margin-top:0;}
.HomePanelWrapper div.Bio p:nth-of-type(2){margin-top:5px; margin-bottom:0;}
.HomePanelWrapper h3{flex:0 0 100%; margin:calc(20px + 0.2rem) auto; color:#EE6914; margin-bottom:2px;}
.HomePanelWrapper h1{flex:0 0 100%; width:100%;}
.HomePanelWrapper h6{flex:0 0 100%; width:100%; margin-bottom:calc(40px + 0.2rem); text-align:center;}
.HomeHighlightWrapper{width:100%; display:flex; flex-wrap:wrap; justify-content:space-between; align-items:stretch; margin:calc(40px + 0.5rem) 0;}
.HomeHighlightWrapper > div{flex:0 0 100%; display:flex; flex-wrap:wrap; align-items:flex-start; justify-content:flex-start; align-content:flex-start; margin-bottom:calc(10px + 1vw); overflow:hidden;}
.HomeHighlightWrapper > div img{width:100%;}
.HomeHighlightWrapper > div.Video{flex:0 0 100%; width:100%; height:0px; font-size:0; line-height:0; padding-top:56.25%; position:relative;}
.HomeHighlightWrapper > div.Video iframe, .HomeHighlightWrapper > div.Video video{width:100%; height:100%; position:absolute; inset:0;}


/* Panel */
.PanelWrapper{width:100%; display:flex; flex-wrap:wrap; justify-content:space-between; align-items:stretch;}
.PanelWrapper h1{flex:0 0 100%; width:100%; margin-bottom:0;}
.PanelWrapper h6{flex:0 0 100%; width:100%; margin-bottom:calc(20px + 2vw); text-align:center; font-size:calc(14px + 0.1rem);}
.PanelWrapper > div{flex:0 0 100%; width:100%; display:flex; flex-wrap:wrap; align-items:flex-start; justify-content:flex-start; align-content:flex-start; margin-bottom:calc(20px + 2vw);}
.PanelWrapper div.Thumbnail, .PanelWrapper div.Bio{flex:0 0 100%; width:100%;}
.PanelWrapper div.Thumbnail > div{width:100%; max-width:160px; overflow:hidden; border-radius:1000px; line-height:0; font-size:0; margin:auto; -webkit-box-shadow:0px 10px 10px 0px rgba(0,0,0,0.2); box-shadow:0px 10px 10px 0px rgba(0,0,0,0.2);}
.PanelWrapper div.Thumbnail img{width:100%; display:block;}
.PanelWrapper div.Bio p{font-size:calc(10px + 0.1rem); margin:calc(10px + 0.1rem) 0px;}
.PanelWrapper div.Bio p:first-of-type, .PanelWrapper div.Bio p:nth-of-type(2), .PanelWrapper div.Bio h5{color:#EC6914; text-align:center;}
.PanelWrapper div.Bio p:first-of-type{margin-bottom:5px;}
.PanelWrapper div.Bio p:nth-of-type(2){margin-top:0;}


/* Highlight */
.HighlightWrapper{width:100%; display:flex; flex-wrap:wrap; justify-content:space-between; align-items:stretch;}
.HighlightWrapper > div{flex:0 0 100%; display:flex; flex-wrap:wrap; align-items:flex-start; justify-content:flex-start; align-content:flex-start; margin-bottom:calc(10px + 1vw); overflow:hidden; cursor:pointer;}
.HighlightWrapper > div img{width:100%;}
.HighlightWrapper > div.Video{flex:0 0 100%; width:100%; height:0px; font-size:0; line-height:0; padding-top:56.25%; position:relative;}
.HighlightWrapper > div.Video iframe, .HighlightWrapper > div.Video video{width:100%; height:100%; position:absolute; inset:0;}


/* Slider */

/* Monuments */
.CarouselWrapper{width:100%; height:auto; margin-bottom:calc(1.6vw + 20px); padding:0vw; margin:0%; font-size:0px; position:relative; overflow:hidden;}
.CarouselInner{width:auto; height:100%; position:relative; top:0%; left:0%; display:flex; flex-wrap:nowrap; align-items:stretch; padding-top:calc(3px + 0.1vw);}
.CarouselInner > *{flex-shrink:0; -webkit-user-select:none; -moz-user-select:none; -ms-user-select:none;  user-select:none;}
.CarouseIndicatorWrapper{position:relative; width:100%; text-align:center; margin-top:calc(1vw + 20px);}
.CarouseIndicator{position:relative; display:inline-block; z-index:10000000000; padding:10px 15px; font-size:0px;}
.CarouseIndicator > span.swiper-pagination-bullet{width:12px; height:12px; background-color:transparent; border:1px solid #24342D; opacity:1; overflow:hidden; list-style:none; margin:0px 3px; display:inline-block; border-radius:100%; outline:none;}
.CarouseIndicator > span.swiper-pagination-bullet-active{border-color:#24342D; background-color:#24342D; cursor:default; color:#24342D;}
.swiper-pagination{text-align:center; -webkit-transition:300ms opacity; o-transition:300ms opacity; transition:300ms opacity; -webkit-transform:translate3d(0, 0, 0); transform:translate3d(0, 0, 0); z-index:10;}
.swiper-pagination-clickable .swiper-pagination-bullet{cursor:pointer;}
.swiper-button-prev, .swiper-button-next{position:absolute; display:none; width:80px; height:80px; overflow:hidden; top:50%; transform:translateY(-50%); user-select:none; cursor:pointer; z-index:9999999; outline:none;}
.swiper-button-prev{left:0px}
.swiper-button-next{right:0px;}
.swiper-button-prev::after, .swiper-button-next::after{content:""; position:absolute; top:0px; left:0px; width:80px; height:80px; z-index:1;}
.swiper-button-next::before, .swiper-button-prev::before{position:absolute; top:25px; font-size:30px; line-height:30px; color:#FFFFFF; display:block; z-index:2;}
.swiper-button-next::before{content:"〉"; left:20px;}
.swiper-button-prev::before{content:"〈"; left:30px;}
.swiper-slide img{width:100%; height:auto;}
.CarouselInner a{text-decoration:none;}
.CarouselInner h3{margin-top:calc(0.5vw + 10px);}
.CarouselInner iframe, .CarouselInner video{position:absolute; top:0; left:0; width:100%; height:100%;}


#HighlightsOverlay{background-color:rgba(88,40,35,0.8); position:fixed; width:100%; height:100vh; inset:0; z-index:999999999999999; -webkit-backdrop-filter:blur(8px); backdrop-filter:blur(8px); display:none; justify-content:center; align-items:center;}
#HighlightsOverlay.Show{display:flex;}
#AlbumSliderWrapper{width:90%; max-width:1600px; height:auto; max-height:75vh; position:relative; overflow:visible;}
#AlbumSliderWrapper .CarouselWrapper, #AlbumSliderWrapper .CarouselInner, #AlbumSliderWrapper .swiper-slide{max-height:75vh; height:100%;}
#AlbumSliderWrapper .swiper-slide img{width:auto; height:auto; max-width:94%; max-height:75vh; display:block; margin:1% auto; -webkit-box-shadow:0px 0px 20px 0px rgba(0,0,0,0.5); box-shadow:0px 0px 20px 0px rgba(0,0,0,0.5);}
#AlbumSliderWrapper .swiper-button-prev{transform:translateX(-100%);}
#AlbumSliderWrapper .swiper-button-next{transform:translateX(100%);}
#AlbumSliderWrapper .CarouseIndicatorWrapper{margin-top:0; white-space:nowrap; height:40px;}
#AlbumSliderWrapper .CarouseIndicator{overflow:hidden;}
#AlbumSliderWrapper .CarouseIndicator > span.swiper-pagination-bullet{border:1px solid #FFFFFF;;}
#AlbumSliderWrapper .CarouseIndicator > span.swiper-pagination-bullet-active{border-color:#FFFFFF; background-color:#FFFFFF; color:#FFFFFF;}
#AlbumClose{width:50px; height:50px; position:absolute; top:0px; right:0px; z-index:10; cursor:pointer; background-color:transparent; border-radius:100px; transform-origin:center center; transition:0.2s ease-in;}
#AlbumClose::before, #AlbumClose::after{content:""; display:block; position:absolute; width:32px; height:2px; top:24px; left:9px; background-color:#FFFFFF; transform-origin:center center;}
#AlbumClose::before{transform:rotate(45deg)}
#AlbumClose::after{transform:rotate(-45deg);}
#AlbumClose:hover{background-color:#24342D; transition:0.2s ease-out;}
#AlbumIndicator{bottom:0;}

#OverlayWrapper{position:fixed; top:0; left:0; width:100%; height:100vh; background-color:rgba(0,0,0,0.8); z-index:10000000000; flex-wrap:nowrap; justify-content:center; align-items:center; pointer-events:none; display:none;}
#OverlayContainer{flex:0 0 90%; width:90%; height:80vh; max-width:1200px; max-height:1300px; text-align:center; object-fit:contain; display:flex; flex-wrap:wrap; justify-content:center; align-items:center; position:relative;}
#OverlayContainer img{display:block; margin:auto; width:auto; height:auto; max-width:90%; max-height:80vh; position:relative;}
#PopupW{display:none;}
#OverlayClose{display:block; flex:0 0 100%; text-align:center;}
#OverlayClose div{width:40px; height:40px; margin:auto; border-radius:200px; background-color:#EE6914; font-size:30px; color:#FFFFFF; line-height:40px; padding:0; user-select:none; cursor:pointer;}

@media (orientation: portrait) {
    #PopupH{display:none;}
    #PopupW{display:block;}
}

@media only screen and (min-width:667px){
    nav{background-color:rgba(255,255,255,0.9); display:flex;}
    #MenuWrapper{width:100%;}
    #MenuWrapper a{flex:0 0 auto; font-size:16px; padding:0px 20px; margin:16px 0; border:none; border-radius:0;}
    #MenuWrapper a:not(:last-of-type){border-right:1px solid rgba(0,0,0,0.1);}
    #MenuWrapper a:hover{background-color:transparent; color:#E8366F;}
    #MenuWrapper a.Selected{padding:8px 20px; margin:8px 0px; border:0; border-radius:6px;}
    #MenuIcon{display:none;}
    .FooterWrapper div a img{max-height:52px;}
    .FooterWrapper div.Organiser a img{max-height:60px;}
    .FooterWrapper div{flex:0 0 auto; width:auto;}
    .PanelWrapper > div{flex:0 0 47%; width:47%;}
    .PanelWrapper div.Thumbnail > div{margin:0;}
    .PanelWrapper div.Bio p:first-of-type, .PanelWrapper div.Bio p:nth-of-type(2), .PanelWrapper div.Bio h5{text-align:left;}
    .HighlightWrapper > div{flex:0 0 48.5%;}
    .HomeHighlightWrapper > div{flex:0 0 32%;}
    .swiper-button-prev, .swiper-button-next{display:block;}
}

@media only screen and (min-width:900px){
    .HomePanelWrapper{justify-content:center;}
    .HomePanelWrapper > div{flex:0 0 33.333%; width:33.333%;}
    .HomePanelWrapper > div:not(:first-of-type){border-left-width:2px; border-left-style:solid; border-image:linear-gradient(to bottom, rgba(134,116,80,0) 0%, rgba(134,116,80,0.1)20%, rgba(134,116,80,0.1) 80%, rgba(134,116,80,0) 100%) 1;}
}

@media only screen and (min-width:1024px){
    #HomeKVWrapper{align-items:center;}
    #HomeKVWrapper > div{flex:0 0 50%; width:50%;}
    #HomeKVWrapper > div img, #HomeKVLeadin{max-width:none; margin-bottom:0;}
}

